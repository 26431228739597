@tailwind base;
@tailwind components;
@tailwind utilities;


/* Custom font */
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
/* Forzar a que el body tenga la fuente de Roboto */
body {
    font-family: 'Roboto', sans-serif;
    }

/* Custom scrollbar */
/* ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    }
::-webkit-scrollbar-track {
    background: #f1f1f1;
    }
::-webkit-scrollbar-thumb {
    background: #888;
    }
::-webkit-scrollbar-thumb:hover {
    background: #555;
    }

     */

